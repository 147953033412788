@use '~@angular/material' as mat;
@import "reset";
@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&display=swap');

$pallete-primary: (
  50: map-get($colors, primary),
  100: map-get($colors, primary),
  200: map-get($colors, primary),
  300: map-get($colors, primary),
  400: map-get($colors, primary),
  500: map-get($colors, primary),
  600: map-get($colors, primary),
  700: map-get($colors, primary),
  800: map-get($colors, primary),
  900: map-get($colors, primary),
  A100: map-get($colors, primary),
  A200: map-get($colors, primary),
  A400: map-get($colors, primary),
  A700: map-get($colors, primary),
  contrast: (50: map-get($colors, primary),
    100: map-get($colors, primary),
    200: map-get($colors, primary),
    300: map-get($colors, primary),
    400: map-get($colors, primary),
    500: map-get($colors, primary),
    600: map-get($colors, primary),
    700: map-get($colors, primary),
    800: map-get($colors, primary),
    900: map-get($colors, primary),
    A100: map-get($colors, primary),
    A200: map-get($colors, primary),
    A400: map-get($colors, primary),
    A700: map-get($colors, primary),
  ),
);

$pallete-accent: (
  50: map-get($colors, accent),
  100: map-get($colors, accent),
  200: map-get($colors, accent),
  300: map-get($colors, accent),
  400: map-get($colors, accent),
  500: map-get($colors, accent),
  600: map-get($colors, accent),
  700: map-get($colors, accent),
  800: map-get($colors, accent),
  900: map-get($colors, accent),
  A100: map-get($colors, accent),
  A200: map-get($colors, accent),
  A400: map-get($colors, accent),
  A700: map-get($colors, accent),
  contrast: (50: map-get($colors, accent),
    100: map-get($colors, accent),
    200: map-get($colors, accent),
    300: map-get($colors, accent),
    400: map-get($colors, accent),
    500: map-get($colors, accent),
    600: map-get($colors, accent),
    700: map-get($colors, accent),
    800: map-get($colors, accent),
    900: map-get($colors, accent),
    A100: map-get($colors, accent),
    A200: map-get($colors, accent),
    A400: map-get($colors, accent),
    A700: map-get($colors, accent),
  ),
);

$rosalyn-www-primary: mat.define-palette($pallete-primary);
$rosalyn-www-accent: mat.define-palette($pallete-accent);
$rosalyn-www-warn: mat.define-palette(mat.$red-palette);

$rosalyn-www-theme: mat.define-light-theme($rosalyn-www-primary, $rosalyn-www-accent, $rosalyn-www-warn);

$rosalyn-typography: mat.define-typography-config($font-family: "Chivo",
  );

@include mat.core($rosalyn-typography);
@include mat.all-component-themes($rosalyn-www-theme);

html,
body {
  overflow: hidden;
  height: 100vh;
  margin: 0;
}

* {
  font-family: "Chivo";
  font-style: normal;
  letter-spacing: -0.01em;
}

.relative-dialog .mat-dialog-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
}

.dialog-x-button {
  position: absolute;
  top: 16px;
  right: 19px;
  z-index: 2;
  overflow: hidden;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 6px;
  padding: 0;
  background-color: #f2f5fa;
  text-decoration: none;
  cursor: pointer;

  &::before {
    content: "+";
    position: absolute;
    top: 0;
    left: 8px;
    display: block;
    color: map-get($colors, light-grey);
    font-size: 20px;
    transform: rotate(45deg);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
}

.custom-select.mat-select-panel {
  min-width: 220px !important;
}

.mat-select-panel {
  transform: none !important;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.app-full-bleed-dialog .mat-dialog-container {
  padding: 0;
}

.confirm-dialog .mat-dialog-container {
  box-shadow: map-get($constants, shadow-basic);
  border-radius: 6px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: map-get($colors, primary);
}

.mat-dialog-container {
  border-radius: 10px !important;
}
